import { isDefined } from "@clipboard-health/util-ts";
import { isAfter, isBefore, parseISO, subMinutes } from "date-fns";

import { AssessmentType, SkillsAssessmentWorkerStatus } from "../types";

export function checkIsWorkerAllowedToTakeAssessment(params: {
  assessmentType?: AssessmentType;
  assessmentStatus?: SkillsAssessmentWorkerStatus;
  assessmentScheduledFor?: string;
  allowedEarlinessInMinutes: number;
  retakeAllowedAfter?: string;
}) {
  const {
    assessmentType,
    assessmentScheduledFor,
    allowedEarlinessInMinutes,
    assessmentStatus,
    retakeAllowedAfter,
  } = params;

  const isTooEarlyForScheduledDate = isDefined(assessmentScheduledFor)
    ? isBefore(new Date(), subMinutes(parseISO(assessmentScheduledFor), allowedEarlinessInMinutes))
    : true;

  if (assessmentStatus === SkillsAssessmentWorkerStatus.PENDING) {
    if (assessmentType === AssessmentType.SCHEDULED) {
      return !isTooEarlyForScheduledDate;
    }

    return true;
  }

  if (assessmentStatus === SkillsAssessmentWorkerStatus.FAILED) {
    const isRetakeDateInPast =
      isDefined(retakeAllowedAfter) && isAfter(new Date(), parseISO(retakeAllowedAfter));
    const isScheduledForAfterRetakeDate =
      isDefined(retakeAllowedAfter) &&
      isDefined(assessmentScheduledFor) &&
      isAfter(parseISO(assessmentScheduledFor), parseISO(retakeAllowedAfter));

    if (assessmentType === AssessmentType.SCHEDULED) {
      return isRetakeDateInPast && isScheduledForAfterRetakeDate && !isTooEarlyForScheduledDate;
    }

    if (assessmentType === AssessmentType.ASYNCHRONOUS) {
      return isRetakeDateInPast;
    }
  }

  return false;
}
