import { isDefined } from "@clipboard-health/util-ts";
import { isAfter, parseISO } from "date-fns";

import { AssessmentType, SkillsAssessmentWorkerStatus } from "../types";

export function checkIsWorkerAllowedToScheduleAssessment(params: {
  assessmentType?: AssessmentType;
  assessmentStatus?: SkillsAssessmentWorkerStatus;
  assessmentScheduledFor?: string;
  retakeAllowedAfter?: string;
}) {
  const { assessmentType, assessmentScheduledFor, assessmentStatus, retakeAllowedAfter } = params;

  if (assessmentType === AssessmentType.ASYNCHRONOUS) {
    return false;
  }

  if (assessmentStatus === SkillsAssessmentWorkerStatus.PENDING) {
    return !isDefined(assessmentScheduledFor);
  }

  if (assessmentStatus === SkillsAssessmentWorkerStatus.FAILED) {
    return isDefined(retakeAllowedAfter) && isAfter(new Date(), parseISO(retakeAllowedAfter));
  }

  return false;
}
