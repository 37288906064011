import { Text } from "@clipboard-health/ui-react";
import { Diagnostic } from "@ionic-native/diagnostic";
import { Button, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useRecordPermissions } from "@src/appV2/SkillsAssessment/api/useRecordPermissions";
import { useEffect, useState } from "react";

import { type SkillsAssessment } from "../../types";
import { isVideoAskMessageEvent } from "../../utils/isVideoAskMessageEvent";

interface Props {
  assessment: SkillsAssessment;
  onCompleted: () => void;
}

export function SkillsAssessmentVideoRecordStep(props: Props) {
  const { assessment, onCompleted } = props;

  const [currentQuestion, setCurrentQuestion] = useState(1);

  useLogEffect(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_VIDEO_ASK_STARTED, { assessment });

  const { isMicrophoneAuthorized, isCameraAuthorized } = useRecordPermissions();
  const hasRequiredPermissionsToRecord = isMicrophoneAuthorized && isCameraAuthorized;

  useEffect(() => {
    function handleVideoAskQuestionMessage(event: MessageEvent) {
      if (isVideoAskMessageEvent(event)) {
        if (event.data.type === "videoask_question_presented") {
          logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_VIDEO_ASK_QUESTION_PRESENTED, {
            assessment,
            currentQuestion,
          });
        }

        if (event.data.type === "videoask_question_submitted") {
          logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_VIDEO_ASK_QUESTION_SUBMITTED, {
            assessment,
            currentQuestion,
          });
          if (currentQuestion + 1 <= assessment.attributes.videoAsk.numberOfQuestions) {
            setCurrentQuestion((question) => question + 1);
          }
        }
      }
    }

    window.addEventListener("message", handleVideoAskQuestionMessage);

    return () => {
      window.removeEventListener("message", handleVideoAskQuestionMessage);
    };
  }, [assessment, currentQuestion]);

  useEffect(() => {
    function handleVideoAskSubmittedMessage(event: MessageEvent) {
      if (isVideoAskMessageEvent(event) && event.data.type === "videoask_submitted") {
        logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_VIDEO_ASK_SUBMITTED, {
          assessment,
        });
        onCompleted();
      }
    }

    window.addEventListener("message", handleVideoAskSubmittedMessage);

    return () => {
      window.removeEventListener("message", handleVideoAskSubmittedMessage);
    };
  }, [assessment, onCompleted]);

  return (
    <Stack spacing={2} width="100%" height="100%" alignItems="center">
      {hasRequiredPermissionsToRecord ? (
        <>
          {/* eslint-disable-next-line react/iframe-missing-sandbox */}
          <iframe
            style={{ border: "none", borderRadius: "2px" }}
            src={assessment.attributes.videoAsk.url}
            title="Skills Assessment"
            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
            width="100%"
            height="100%"
            onError={(event) => {
              logError(APP_V2_APP_EVENTS.SKILLS_ASSESSMENTS_IFRAME_ERROR, { error: { event } });
            }}
          />
          <Text bold>
            Question {currentQuestion} of {assessment.attributes.videoAsk.numberOfQuestions}
          </Text>
        </>
      ) : (
        <>
          {isMicrophoneAuthorized ? null : (
            <Text>Please provide permission for the microphone</Text>
          )}
          {isCameraAuthorized ? null : <Text>Please provide permission for the camera</Text>}
          <Button
            onClick={() => {
              // At this point, we know that the user has not granted the permissions so we need to switch to
              // the Settings to let the user grant the permissions.
              // We cannot invoke the native modal/dialog for the permission (using
              // requestMicrophoneAuthorization or requestCameraAuthorization) because it will not be shown.
              // The native modal/dialog is only shown when the app has not requested the permission yet.
              void Diagnostic.switchToSettings();
            }}
          >
            Open Settings
          </Button>
        </>
      )}
    </Stack>
  );
}
